import React, { useEffect } from "react";

const ScrollToTop = () => {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <div>
      <button
        onClick={() => {
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        }}
        style={{
          position: "fixed",
          padding: "8px 0 0 0",
          width: "40px",
          height: "40px",
          fontSize: "20px",
          bottom: "40px",
          right: "40px",
          backgroundColor: "transparent",
          color: "#000",
        //   display: "flex",
        //   justifyContent: "center",
        //   alignItems: "center",
          border: "1px solid #000"
        }}
      >
        ^
      </button>
    </div>
  );
};

export default ScrollToTop;
