import React from "react";
import "./InforPage.scss";
import { Helmet } from "react-helmet";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import IntroductoryVideo from "../../assets/videos/1.mp4";
import aboutusImg1 from "../../assets/images/aboutus/1.webp";
import aboutusImg2 from "../../assets/images/aboutus/2.webp";
import aboutusImg3 from "../../assets/images/aboutus/3.webp";
import aboutusImg4 from "../../assets/images/aboutus/4.webp";

import gallery1 from "../../assets/images/gallery/1.jpg";
import gallery2 from "../../assets/images/gallery/2.jpg";
import gallery3 from "../../assets/images/gallery/3.jpg";
import gallery4 from "../../assets/images/gallery/4.jpg";
import gallery5 from "../../assets/images/gallery/5.jpg";
import gallery6 from "../../assets/images/gallery/6.jpg";
import gallery7 from "../../assets/images/gallery/7.avif";
import gallery72 from "../../assets/images/gallery/7.jpg";
import gallery8 from "../../assets/images/gallery/8.jpg";
import gallery9 from "../../assets/images/gallery/9.jpg";

import ChooseUs from "../../components/ChooseUs";
import WatchUs from "../../components/WatchUs";
import Certificate from "../../components/Certificate";
import { Link } from "react-router-dom";

const aboutUsList = [
  {
    id: 1,
    img: aboutusImg1,
    title: (
      <FormattedMessage
        id="infor.introduce.aboutus.title1"
        defaultMessage="58"
      />
    ),
    text: (
      <FormattedMessage
        id="infor.introduce.aboutus.text1"
        defaultMessage="Giao dịch với hơn 50 quốc gia"
      />
    ),
  },
  {
    id: 2,
    img: aboutusImg2,
    title: (
      <FormattedMessage
        id="infor.introduce.aboutus.title2"
        defaultMessage="1.020"
      />
    ),
    text: (
      <FormattedMessage
        id="infor.introduce.aboutus.text2"
        defaultMessage="Hơn 1.000 ha đất nông nghiệp"
      />
    ),
  },
  {
    id: 3,
    img: aboutusImg3,
    title: (
      <FormattedMessage
        id="infor.introduce.aboutus.title3"
        defaultMessage="100.000"
      />
    ),
    text: (
      <FormattedMessage
        id="infor.introduce.aboutus.text3"
        defaultMessage="100.000 cây điều đã được nuôi trồng"
      />
    ),
  },
  {
    id: 4,
    img: aboutusImg4,
    title: (
      <FormattedMessage
        id="infor.introduce.aboutus.title4"
        defaultMessage="880.000"
      />
    ),
    text: (
      <FormattedMessage
        id="infor.introduce.aboutus.text4"
        defaultMessage="Sản lượng điều hàng tháng đạt 880.000 tấn"
      />
    ),
  },
];

const galleryList = [
  {
    img: gallery1,
    figcaption: "Liên Hệ Chúng Tôi",
  },
  {
    img: gallery2,
    figcaption: "Liên Hệ Chúng Tôi",
  },
  {
    img: gallery3,
    figcaption: "Liên Hệ Chúng Tôi",
  },
  {
    img: gallery72,
    figcaption: "Liên Hệ Chúng Tôi",
  },
  // {
  //   img: gallery5,
  //   figcaption: "Liên Hệ Chúng Tôi",
  // },
  {
    img: gallery6,
    figcaption: "Liên Hệ Chúng Tôi",
  },
  {
    img: gallery7,
    figcaption: "Liên Hệ Chúng Tôi",
  },
  {
    img: gallery8,
    figcaption: "Liên Hệ Chúng Tôi",
  },
  {
    img: gallery9,
    figcaption: "Liên Hệ Chúng Tôi",
  },
];

const InforIntroductoryVideo = () => {
  return (
    <div className="relative">
      <video
        // className="videoIntro"
        id="videoIntro"
        style={{
          zIndex: 1,
        }}
        className="w-full h-[800px] object-cover"
        autoPlay
        loop
        src={IntroductoryVideo}
        muted
        // playsInline=""
        // preload="auto"
      />
      <h1 className="w-[90%] text-4xl absolute text-center font-bold top-1/2 left-1/2 transform translate-x-[-50%] translate-y-[-50%] lg:text-6xl text-white">
        <FormattedMessage
          id="infor.introduce.video.text1"
          defaultMessage="Giới thiệu về NÔNG SẢN HẢI PHÁT"
        />
        <br />
        <FormattedMessage
          id="infor.introduce.video.text2"
          defaultMessage="'Để bạn thưởng thức vị ngon trên từng hạt điều'"
        />
      </h1>
    </div>
  );
};

const InforIntroductoryCompany = () => {
  return (
    <div className="bg-gray-100 py-10 h-[500px]">
      <h1
        className="w-[100%] text-2xl lg:text-6xl text-center font-bold text-black py-10"
        data-aos="fade-up"
        data-aos-offset="200"
        data-aos-easing="ease-in-sine"
        data-aos-duration="600"
      >
        <FormattedMessage
          id="infor.introduce.company.text1"
          defaultMessage="Giới thiệu về NÔNG SẢN HẢI PHÁT"
        />
      </h1>
      <p
        className="w-[100%] text-base lg:text-xl text-center text-black px-20 lg:px-40 py-10"
        data-aos="fade-up"
        data-aos-offset="200"
        data-aos-easing="ease-in-sine"
        data-aos-duration="600"
      >
        <FormattedMessage
          id="infor.introduce.company.text2"
          defaultMessage="NÔNG SẢN HẢI PHÁT được thành lập vào năm 2015. Công ty luôn hướng đến sự phát triển cung cấp các sản phẩm nông nghiệp, đặc biệt là hạt điều trên toàn cầu. Các sản phẩm của Hải Phát có nguồn gốc xuất xứ rõ ràng như Việt Nam, Indonesia, Campuchia, Châu Phi... Với mục tiêu mang lại sự an tâm cho người tiêu dùng và để mọi người thưởng thức những sản phẩm nông nghiệp tốt cho sức khỏe."
        />
      </p>
    </div>
  );
};

const InforIntroductoryAboutUs = () => {
  return (
    <div className="py-10 flex flex-col md:flex-row justify-center items-center InforIntroductoryAboutUs_wrapper">
      {/* <h1 className="w-[100%] text-2xl lg:text-4xl text-center font-bold text-black py-10">
          <FormattedMessage
            id="infor.introduce.company.text1"
            defaultMessage="Giới thiệu về NÔNG SẢN HẢI PHÁT"    
          />
        </h1> */}
      {aboutUsList.map((item, index) => (
        <div key={index} className="w-[240px] h-[600px] bg-white px-5 m-5" 
        data-aos="fade-up"
          data-aos-offset="200"
          data-aos-easing="ease-in-sine"
          data-aos-duration="600">
          <div className="h-1/2 w-full flex justify-center items-end">
            <img className="w-full px-5" src={item.img} alt="aboutusImg" />
          </div>
          <div className="h-1/2 w-full flex flex-col justify-center items-center text-center  ">
            <h1 className="text-4xl lg:text-5xl font-bold py-2">
              {item.title}
            </h1>
            <p className="text-xl font-bold py-2">{item.text}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

const InforIntroductoryGallery = () => {
  return (
    <section className="InforIntroductoryGallery_wrapper">
      <ul className="InforIntroductoryGallery_list">
        {galleryList.map((item, index) => (
          <li key={index}>
            <Link to="/contact">
              <figure>
                <img src={item.img} alt={item.figcaption} />
                <figcaption>{item.figcaption}</figcaption>
              </figure>
            </Link>
          </li>
        ))}
      </ul>
    </section>
  );
};

const InforPage = () => {
  const languageState = useSelector((state) => state.language);
  const { locale } = languageState;

  return (
    <section className="InforPage_wrapper">
      <Helmet>
        <title>
          {locale === "vi" || locale === "vi-VN"
            ? "THÔNG TIN | NÔNG SẢN HẢI PHÁT"
            : "INFORMATION | HAI PHAT AGRICULTURAL"}
        </title>
        <meta
          name="description"
          content={
            <FormattedMessage
              id="helmet.infor"
              defaultMessage="THÔNG TIN | NÔNG SẢN HẢI PHÁT"
            />
          }
        />
        <meta
          property="og:title"
          content={
            <FormattedMessage
              id="helmet.infor"
              defaultMessage="THÔNG TIN | NÔNG SẢN HẢI PHÁT"
            />
          }
        />
        <meta
          property="og:description"
          content={
            <FormattedMessage
              id="helmet.infor"
              defaultMessage="THÔNG TIN | NÔNG SẢN HẢI PHÁT"
            />
          }
        />
        <meta
          property="og:image"
          content={`https://cdn-prod.medicalnewstoday.com/content/images/articles/325/325253/assortment-of-fruits.jpg`}
        />
      </Helmet>

      <InforIntroductoryVideo />
      <ChooseUs />
      <InforIntroductoryCompany />
      <InforIntroductoryAboutUs />
      <WatchUs />
      <InforIntroductoryGallery />
      <Certificate />
    </section>
  );
};

export default InforPage;
