import React from "react";
import "./WatchUs.scss";
import { FormattedMessage } from "react-intl";
import bg1 from "../../assets/images/watchus/b03_bg.png";

const WatchUs = () => {
  return (
    <section className="WatchUs_wrapper w-full h-auto py-[2%]">
      <h1
        className="text-center text-6xl font-bold capitalize py-5"
        data-aos="fade-up"
        data-aos-offset="200"
        data-aos-easing="ease-in-sine"
        data-aos-duration="600"
      >
        <FormattedMessage
          id="watchus.title"
          defaultMessage="Xem video của chúng tôi"
        />
      </h1>
      <div
        className="flex my-10 relative overflow-hidden"
        data-aos="fade-up"
        data-aos-offset="200"
        data-aos-easing="ease-in-sine"
        data-aos-duration="600"
      >
        <div className="relative flex w-[100%] lg:w-[80%] justify-center items-center">
          <div className="absolute h-80 w-[900px] inset-0 bg-orange-600 rounded-3xl top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-0 "></div>

          <iframe
            height="500"
            src="https://www.youtube.com/embed/aso_fW7__p8?si=wQLhjOe4RtL_eh4u"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
            className="z-10 w-[400px] md:w-[700px]"
          />
        </div>
        <div className="hidden lg:block absolute w-[500px] bottom-0 right-0 translate-x-0">
          <img src={bg1} alt="Background" className="w-806 h-664 object-cover" />
        </div>
        {/* <div className="flex w-[30%] items-center justify-end overflow-hidden">
            <img src={bg1} alt="b03_bg" className="translate-x-20 translate-y-[-0.5em]" />
        </div> */}
      </div>
    </section>
  );
};

export default WatchUs;
