import { configureStore } from "@reduxjs/toolkit";
import cartReducer from "./features/CartSlice";
import languageReducer from "./features/LanguageSlice";

const store = configureStore({
  reducer: {
    cart: cartReducer,
    language: languageReducer,
  },
});

export default store;
