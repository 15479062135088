import React from "react";
import { FormattedMessage } from "react-intl";

const Footer = () => {
  return (
    <section className="mx-auto p-5 w-full bg-background font-sans">
      <p className="text-center">
        &copy; {" "}
        <FormattedMessage
          id="footer.copyright"
          defaultMessage=" Bản quyền 2015-2024"
        />
        <span>
          {" "}
          <FormattedMessage
            id="footer.company"
            defaultMessage="CÔNG TY TNHH NÔNG SẢN HẢI PHÁT"
          />
        </span>
      </p>
    </section>
  );
};

export default Footer;
