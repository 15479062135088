import React, { Suspense } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import { IntlProvider } from "react-intl";

import SharedLayout from "./components/SharedLayout";
import { useSelector } from "react-redux";
import InforPage from "./pages/InforPage";
const HomePage = React.lazy(() => import("./pages/HomePage"));
const NutsPage = React.lazy(() => import("./pages/FruitsPage"));
const Fruit = React.lazy(() => import("./components/Fruit"));
const ServicePage = React.lazy(() => import("./pages/ServicePage"));
const ContactPage = React.lazy(() => import("./pages/ContactPage"));
const PageNotFound = React.lazy(() => import("./pages/PageNotFound"));
const LoginPage = React.lazy(() => import("./pages/LoginPage"));
const RegisterPage = React.lazy(() => import("./pages/RegisterPage"));
const CartPage = React.lazy(() => import("./pages/CartPage"));
const CheckoutPage = React.lazy(() => import("./pages/CheckoutPage"));

function App() {
  const languageState = useSelector((state) => state.language);
  const { locale, messages } = languageState;

  return (
    <IntlProvider messages={messages} locale={locale}>
      <Suspense fallback={<div>Loading...</div>}>
        <Router>
          <Routes>
            <Route element={<SharedLayout />}>
              <Route path="/" index element={<HomePage />} />
              <Route
                path="/information"
                index
                element={<InforPage />}
              />
              <Route path="/nuts" index element={<NutsPage />} />
              <Route
                path="/services"
                index
                element={<ServicePage />}
              />
              <Route
                path="/contact"
                index
                element={<ContactPage />}
              />
              <Route path="/login" index element={<LoginPage />} />
              <Route
                path="/register"
                index
                element={<RegisterPage />}
              />
              <Route path="/nut/:id" element={<Fruit />} />
              <Route path="/cart" element={<CartPage />} />
              <Route path="/checkout" element={<CheckoutPage />} />
            </Route>
            <Route path="*" element={<PageNotFound />} />
            <Route path="/nut/*" element={<PageNotFound />} />
          </Routes>
        </Router>
      </Suspense>
    </IntlProvider>
  );
}

export default App;
