import React from "react";
import certificate1 from "../../assets/images/certificate/1.png";
import certificate2 from "../../assets/images/certificate/2.png";
import certificate3 from "../../assets/images/certificate/3.png";
import { FormattedMessage } from "react-intl";

const Certificate = () => {
  return (
    <section className="Certificate_wrapper w-full h-auto py-[2%] max-md:max-w-[600px] mx-auto bg-background    ">
      <h1
        className="text-center text-6xl font-bold capitalize py-5"
        data-aos="fade-up"
        data-aos-offset="200"
        data-aos-easing="ease-in-sine"
        data-aos-duration="600"
      >
        <FormattedMessage id="certificate.title" defaultMessage="Chứng Nhận" />
      </h1>
      <div
        className="flex justify-evenly items-center my-10"
        data-aos="fade-up"
        data-aos-offset="200"
        data-aos-easing="ease-in-sine"
        data-aos-duration="600"
      >
        <img src={certificate1} alt="certificate" />
        <img src={certificate2} alt="certificate" />
        {/* <img src={certificate3} alt="certificate" /> */}
      </div>
    </section>
  );
};

export default Certificate;
