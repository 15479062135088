import React from "react";
import "./Info.scss";
import fb_icon from "../../assets/images/fb.png";
import twitter_icon from "../../assets/images/twitter.png";
import linkedin_icon from "../../assets/images/linkedin.png";
import instagram_icon from "../../assets/images/instagram.png";
import zalo_icon from "../../assets/images/zalo.png";
import whatsapp_icon from "../../assets/images/whatsapp.png";
import phone_icon from "../../assets/images/phone.png";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

const infoItems = [
  {
    id: 1,
    title: <FormattedMessage id="nav.infor" defaultMessage="Thông Tin" />,
    items: [
      // {
      //   text: <FormattedMessage id="contact.address.no" defaultMessage="Số 328, ấp Bàu Cối," />,
      // },
      // {
      //   text: <FormattedMessage id="contact.address.commune" defaultMessage="Xã Bảo Quang," />,
      // },
      // {
      //   text: <FormattedMessage id="contact.address.city" defaultMessage="Thành phố Long khánh," />,
      // },
      // {
      //   text: <FormattedMessage id="contact.address.province" defaultMessage="Tỉnh Đồng Nai," />,
      // },
      // {
      //   text: <FormattedMessage id="contact.address.country" defaultMessage="Việt Nam" />,
      // },
      {
        text: (
          <FormattedMessage
            id="contact.infor.factory"
            defaultMessage="Nhà máy:  Xã Bảo Quang, Thành phố Long Khánh, Tỉnh Đồng Nai"
          />
        ),
      },
      {
        text: (
          <FormattedMessage
            id="contact.infor.office"
            defaultMessage="Văn phòng: 1806/127 Huỳnh Tấn Phát, Thị trấn Nhà Bè, Huyện Nhà Bè, Tp.HCM"
          />
        ),
      },
      {
        text: "Email: yun.tran@haiphatagri.com.vn",
      },
      {
        text: "Mobile: +84 902464624",
      },
    ],
  },
  {
    id: 2,
    title: (
      <FormattedMessage
        id="footer.usefullinks"
        defaultMessage="Liên Kết Hữu Dụng"
      />
    ),
    items: [
      {
        text: <FormattedMessage id="nav.home" defaultMessage="Trang Chủ" />,
        href: "/",
      },
      {
        text: <FormattedMessage id="nav.infor" defaultMessage="Thông Tin" />,
        href: "/information",
      },
      {
        text: <FormattedMessage id="nav.nuts" defaultMessage="Sản Phẩm" />,
        href: "/nuts",
      },
      {
        text: <FormattedMessage id="nav.services" defaultMessage="Quy Trình" />,
        href: "/services",
      },
      {
        text: <FormattedMessage id="nav.contact" defaultMessage="Liên Hệ" />,
        href: "/contact",
      },
    ],
  },
];
const socialItems = [
  {
    id: 1,
    img: phone_icon,
    href: "tel:0938932440",
  },
  {
    id: 2,
    img: fb_icon,
    href: "https://www.facebook.com/dung.b.tran",
  },
  // {
  //   id: 2,
  //   img: twitter_icon,
  //   href: "https://github.com/hoanglg-workplace",
  // },
  // {
  //   id: 3,
  //   img: linkedin_icon,
  //   href: "https://www.linkedin.com/in/hoanglg-work/",
  // },
  // {
  //   id: 4,
  //   img: instagram_icon,
  //   href: "https://github.com/hoanglg-workplace",
  // },
  {
    id: 5,
    img: zalo_icon,
    href: "https://zalo.me/0902464624",
  },
  {
    id: 6,
    img: whatsapp_icon,
    href: "https://wa.me/+84902464624",
  },
];
const Info = () => {
  return (
    <section className="w-full h-auto py-[2%] bg-white info">
      <div className="max-w-[1280px] max-md:max-w-[600px] w-full mx-auto p-4">
        <div className="flex flex-wrap max-md:flex-col text-center justify-center items-center md:items-start">
          {infoItems.map((item) => (
            // <div className="col-md-8 md:col-md-6" key={item.id} >
            <div className="w-1/2 lg:w-1/3" key={item.id}>
              <h5 className="text-2xl mb-2 max-md:mt-10 font-bold">
                {item.title}
              </h5>
              <ul>
                {item?.items.map((childItem, index) =>
                  childItem.href ? (
                    <a href={childItem.href} target="_self" rel="" key={index} className="my-1 ">
                      <li>{childItem.text}</li>
                    </a>
                  ) : (
                    <li key={index} className="my-1 ">
                      {childItem.text}
                    </li>
                  )
                )}
              </ul>
            </div>
          ))}

          <div className="">
            <div>
              <h5 className="text-2xl mb-2 mt-10 font-bold">
                <FormattedMessage
                  id="footer.followus"
                  defaultMessage="Theo Dõi Chúng Tôi"
                />
              </h5>
              <div className="my-6 flex justify-center items-center">
                {socialItems.map((item) => (
                  <a
                    key={item.id}
                    target="_blank"
                    rel="noreferrer"
                    href={item.href}
                  >
                    <img
                      width={35}
                      className="mr-1"
                      src={item.img}
                      alt="social_icon"
                    />
                  </a>
                ))}
              </div>
            </div>
            <div>
              <h5 className="text-2xl font-bold">
                <FormattedMessage
                  id="footer.subscribe"
                  defaultMessage="Đăng Ký Ngay"
                />
              </h5>
              <div>
                <form action="">
                  <input
                    className="my-5 w-[200px] h-7 border border-black"
                    placeholder="Email"
                    type="email"
                  />
                  <button
                    className="custom_orange-button py-1 mx-2"
                    type="submit"
                  >
                    Subscribe
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Info;
