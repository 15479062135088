import React, { useEffect } from "react";
import "./ChooseUs.scss";

import Aos from "aos";
import "aos/dist/aos.css";
import { FormattedMessage, useIntl } from "react-intl";
import c1 from "../../assets/images/chooseus/1.png";
import c2 from "../../assets/images/chooseus/2.png";
import c3 from "../../assets/images/chooseus/3.png";
import c4 from "../../assets/images/chooseus/4.png";
import { TypeAnimation } from "react-type-animation";

const ChooseUs = () => {
  const chooseUsList = [
    {
      img: c1,
      title: (
        <FormattedMessage
          id="chooseus.title.c1"
          defaultMessage="Nhà Máy Đạt Tiêu Chuẩn & Sản Phẩm Chất Lượng"
        />
      ),
      content: (
        <FormattedMessage
          id="chooseus.content.c1"
          defaultMessage="Nhà máy của chúng tôi đáp ứng tất cả các tiêu chuẩn để xuất khẩu ra toàn cầu và truy xuất nguồn gốc đầy đủ. Chất lượng của chúng tôi đáp ứng được cả những khách hàng khó tính nhất. Chúng tôi có thể cung cấp chất lượng khác nhau dựa trên tiêu chuẩn AFI (thị trường Châu Âu / Hoa Kỳ) cũng như chất lượng cao của Trung Quốc, v.v."
        />
      ),
    },
    {
      img: c2,
      title: (
        <FormattedMessage
          id="chooseus.title.c2"
          defaultMessage="Giá cả cạnh tranh và giao hàng đúng giờ"
        />
      ),
      content: (
        <FormattedMessage
          id="chooseus.content.c2"
          defaultMessage="Chúng tôi đảm bảo giao hàng đúng thời gian và giá cả cạnh tranh nhất ."
        />
      ),
    },
    {
      img: c3,
      title: (
        <FormattedMessage
          id="chooseus.title.c3"
          defaultMessage="Đặt hàng đơn giản và giao dịch an toàn"
        />
      ),
      content: (
        <FormattedMessage
          id="chooseus.content.c3"
          defaultMessage="Đặt Hàng Đơn Giản Và Giao Dịch Nhanh chóng
          Bạn hãy cho chúng tôi biết yêu cầu của bạn, chúng tôi sẽ gắng đáp ứng đầy đủ nhất và nhanh chóng nhất."
        />
      ),
    },
    {
      img: c4,
      title: (
        <FormattedMessage
          id="chooseus.title.c4"
          defaultMessage="Đội ngũ nhân viên chuyên nghiệp & hợp tác lâu dài"
        />
      ),
      content: (
        <FormattedMessage
          id="chooseus.content.c4"
          defaultMessage="Mục tiêu không bao giờ kết thúc của chúng tôi là mang lại sản phẩm hạt điều với chất lượng tốt nhất và mang lại niềm tin lâu dài cho khách hàng quý giá của chúng tôi."
        />
      ),
    },
  ];

  const intl = useIntl();
  useEffect(() => {
    Aos.init();
  }, []);

  const whychoseus1 = intl.formatMessage({
    id: "chooseus.title.1",
    defaultMessage: "Vì sao nên chọn chúng tôi",
  });

  const whychoseus2 = intl.formatMessage({
    id: "chooseus.title.2",
    defaultMessage: "Vì sao nên chọn nông sản hải phát",
  });

  return (
    <section className="ChooseUs_wrapper w-full p-10 md:p-20 lg:p-28 ">
      <h1 className="py-5 uppercase text-center font-bold mb-20 text-4xl lg:text-5xl leading-10">
        <TypeAnimation
          preRenderFirstString={true}
          sequence={[1000, whychoseus1, 4000, whychoseus2, 1000]}
          speed={50}
          repeat={Infinity}
        />
      </h1>
      <div className="grid grid-cols-1 md:grid-cols-2 place-items-start">
        {chooseUsList.map((item, index) => (
          <div
            data-aos="fade-up"
            data-aos-offset="200"
            data-aos-easing="ease-in-sine"
            data-aos-duration="600"
            key={index}
            className="border h-[52vh] w-full flex flex-col justify-center items-center p-10"
          >
            <div>
              <img src={item.img} alt="chooseus" className="" />
            </div>
            <h4 className="capitalize text-center font-bold text-xl py-4">
              {item.title}
            </h4>
            <p>{item.content}</p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default ChooseUs;
